import type { ApiOldLangPack } from '../api/types';

// export const fallbackLangPackInitial = {
//   WrongNumber: '號碼錯誤？',
//   SentAppCode: '我們已經將代碼發送到你其他設備上的 **Telegram** 應用程式。',
//   'Login.JustSentSms': '我們已經通過 SMS 發送了代碼。請在上方輸入它。',
//   'Login.Header.Password': '輸入密碼',
//   'Login.EnterPasswordDescription': '你啟用了兩步驟驗證，因此你的帳戶有額外的密碼保護。',
//   StartText: '請確認你的國家代碼並輸入你的電話號碼。',
//   'Login.PhonePlaceholder': '你的電話號碼',
//   'Login.Next': '下一步',
//   'Login.QR.Login': '使用 QR 碼登入',
//   'Login.QR.Title': '通過 QR 碼登入 Telegram',
//   'Login.QR.Help1': '在你的手機上打開 Telegram',
//   'Login.QR.Help2': '前往 **設定** > **裝置** > **連接桌面裝置**',
//   'Login.QR2.Help2': '前往 **設定** → **裝置** → **連接桌面裝置**',
//   'Login.QR.Help3': '將你的手機對準此螢幕以確認登入',
//   'Login.QR.Cancel': '使用電話號碼登入',
//   YourName: '你的名字',
//   'Login.Register.Desc': '輸入你的名字並添加一張個人照片。',
//   'Login.Register.FirstName.Placeholder': '名字',
//   'Login.Register.LastName.Placeholder': '姓氏',
//   'Login.SelectCountry.Title': '國家',
//   lng_country_none: '找不到國家',
//   PleaseEnterPassword: '輸入你的新密碼',
//   PHONE_NUMBER_INVALID: '無效的電話號碼',
//   PHONE_CODE_INVALID: '無效的代碼',
//   PASSWORD_HASH_INVALID: '密碼錯誤',
//   PHONE_PASSWORD_FLOOD: '超出限制。請稍後再試。',
//   PHONE_NUMBER_BANNED: '此電話號碼已被禁止。',
// } as ApiOldLangPack;

export const fallbackLangPackInitial = {
  WrongNumber: 'Wrong number?',
  SentAppCode: 'We have sent the code to the **Telegram** app on your other devices.',
  'Login.JustSentSms': 'We have sent the code via SMS. Please enter it above.',
  'Login.Header.Password': 'Enter password',
  'Login.EnterPasswordDescription': 'You have enabled two-step verification, so your account is protected with an extra password.',
  StartText: 'Please confirm your country code and enter your phone number.',
  'Login.PhonePlaceholder': 'Your phone number',
  'Login.Next': 'Next',
  'Login.QR.Login': 'Log in with QR Code',
  'Login.QR.Title': 'Log in to Telegram by QR Code',
  'Login.QR.Help1': 'Open Telegram on your phone',
  'Login.QR.Help2': 'Go to **Settings** > **Devices** > **Link Desktop Device**',
  'Login.QR2.Help2': 'Go to **Settings** → **Devices** → **Link Desktop Device**',
  'Login.QR.Help3': 'Point your phone at this screen to confirm login',
  'Login.QR.Cancel': 'Log in with phone number',
  YourName: 'Your name',
  'Login.Register.Desc': 'Enter your name and add a profile photo.',
  'Login.Register.FirstName.Placeholder': 'First name',
  'Login.Register.LastName.Placeholder': 'Last name',
  'Login.SelectCountry.Title': 'Country',
  lng_country_none: 'Country not found',
  PleaseEnterPassword: 'Enter your new password',
  PHONE_NUMBER_INVALID: 'Invalid phone number',
  PHONE_CODE_INVALID: 'Invalid code',
  PASSWORD_HASH_INVALID: 'Incorrect password',
  PHONE_PASSWORD_FLOOD: 'Limit exceeded. Please try again later.',
  PHONE_NUMBER_BANNED: 'This phone number is banned.',
} as ApiOldLangPack;

