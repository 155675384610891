/* eslint-disable */
// This file is generated by dev/generateInitialLangFallback.ts. Do not edit it manually.

// export default {
//   "WrongNumber": "號碼錯誤？",
//   "SentAppCode": "我們已經將代碼發送到你其他設備上的 **Telegram** 應用程式。",
//   "LoginJustSentSms": "我們已經透過 SMS 發送了代碼。請在上方輸入它。",
//   "LoginHeaderPassword": "輸入密碼",
//   "LoginEnterPasswordDescription": "你啟用了兩步驟驗證，因此你的帳戶有額外的密碼保護。",
//   "StartText": "請確認你的國家代碼並輸入你的電話號碼。",
//   "LoginPhonePlaceholder": "你的電話號碼",
//   "LoginNext": "下一步",
//   "LoginQRLogin": "使用 QR 碼登入",
//   "LoginQRTitle": "通過 QR 碼登入 Telegram",
//   "LoginQRHelp1": "在你的手機上打開 Telegram",
//   "LoginQR2Help2": "前往 **設定** > **裝置** > **連接桌面裝置**",
//   "LoginQRHelp3": "將你的手機對準此螢幕以確認登入",
//   "LoginQRCancel": "使用電話號碼登入",
//   "YourName": "你的名字",
//   "LoginRegisterDesc": "輸入你的名字並添加一張個人照片。",
//   "LoginRegisterFirstNamePlaceholder": "名字",
//   "LoginRegisterLastNamePlaceholder": "姓氏",
//   "LoginSelectCountryTitle": "國家",
//   "CountryNone": "找不到國家",
//   "PleaseEnterPassword": "輸入你的新密碼"
// } as Record<string, string>;

// export default {
//   "WrongNumber": "号码错误？",
//   "SentAppCode": "我们已经将代码发送到你其他设备上的 **Telegram** 应用程序。",
//   "LoginJustSentSms": "我们已经通过 SMS 发送了代码。请在上方输入它。",
//   "LoginHeaderPassword": "输入密码",
//   "LoginEnterPasswordDescription": "你启用了两步骤验证，因此你的账户有额外的密码保护。",
//   "StartText": "请确认你的国家代码并输入你的电话号码。",
//   "LoginPhonePlaceholder": "你的电话号码",
//   "LoginNext": "下一步",
//   "LoginQRLogin": "使用 QR 码登录",
//   "LoginQRTitle": "通过 QR 码登录 Telegram",
//   "LoginQRHelp1": "在你的手机上打开 Telegram",
//   "LoginQR2Help2": "前往 **设置** > **设备** > **连接桌面设备**",
//   "LoginQRHelp3": "将你的手机对准此屏幕以确认登录",
//   "LoginQRCancel": "使用电话号码登录",
//   "YourName": "你的名字",
//   "LoginRegisterDesc": "输入你的名字并添加一张个人照片。",
//   "LoginRegisterFirstNamePlaceholder": "名字",
//   "LoginRegisterLastNamePlaceholder": "姓氏",
//   "LoginSelectCountryTitle": "国家",
//   "CountryNone": "找不到国家",
//   "PleaseEnterPassword": "输入你的新密码"
// } as Record<string, string>;

export default {
  "WrongNumber": "Wrong number?",
  "SentAppCode": "We've sent the code to the **Telegram** app on your other device.",
  "LoginJustSentSms": "We've sent you a code via SMS. Please enter it above.",
  "LoginHeaderPassword": "Enter Password",
  "LoginEnterPasswordDescription": "You have Two-Step Verification enabled, so your account is protected with an additional password.",
  "StartText": "Please confirm your country codenand enter your phone number.",
  "LoginPhonePlaceholder": "Your phone number",
  "LoginNext": "Next",
  "LoginQRLogin": "Log in by QR Code",
  "LoginQRTitle": "Log in to Telegram by QR Code",
  "LoginQRHelp1": "Open Telegram on your phone",
  "LoginQR2Help2": "Go to **Settings** > **Devices** > **Link Desktop Device**",
  "LoginQRHelp3": "Point your phone at this screen to confirm login",
  "LoginQRCancel": "Log in by phone Number",
  "YourName": "Your Name",
  "LoginRegisterDesc": "Enter your name and add a profile photo.",
  "LoginRegisterFirstNamePlaceholder": "First Name",
  "LoginRegisterLastNamePlaceholder": "Last Name",
  "LoginSelectCountryTitle": "Country",
  "CountryNone": "Country not found",
  "PleaseEnterPassword": "Enter your new password"
} as Record<string, string>;
