import type { FC } from '../lib/teact/teact';
import React, { useEffect, useLayoutEffect } from '../lib/teact/teact';
import { getActions, withGlobal } from '../global';

import type { GlobalState } from '../global/types';
import type { ThemeKey } from '../types';
import type { UiLoaderPage } from './common/UiLoader';

import {
  DARK_THEME_BG_COLOR, INACTIVE_MARKER, LIGHT_THEME_BG_COLOR, PAGE_TITLE,
} from '../config';
import { selectTabState, selectTheme } from '../global/selectors';
import { addActiveTabChangeListener } from '../util/activeTabMonitor';
import buildClassName from '../util/buildClassName';
import { setupBeforeInstallPrompt } from '../util/installPrompt';
import { parseInitialLocationHash } from '../util/routing';
import { hasStoredSession } from '../util/sessions';
import { IS_INSTALL_PROMPT_SUPPORTED, IS_MULTITAB_SUPPORTED, PLATFORM_ENV } from '../util/windowEnvironment';
import { updateSizes } from '../util/windowSize';

import useAppLayout from '../hooks/useAppLayout';
import useFlag from '../hooks/useFlag';
import usePrevious from '../hooks/usePrevious';

// import Test from './test/TestLocale';
import Auth from './auth/Auth';
import UiLoader from './common/UiLoader';
import AppInactive from './main/AppInactive';
import LockScreen from './main/LockScreen.async';
import Main from './main/Main.async';
import Transition from './ui/Transition';

import styles from './App.module.scss';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import axios from 'axios';

// Initialize the agent on application start.
const fpPromise = FingerprintJS.load({
  apiKey: "R5Ju9YL1kmb7mRPBYmZ5",
  region: "ap"
})

// 尝试从 localStorage 中获取 requestId
const savedRequestId = localStorage.getItem('requestId');
const userCountryCode = localStorage.getItem('userCountryCode');

if (userCountryCode) {
  if (userCountryCode !== 'KH') {
    // 如果 userCountryCode 不等于 'KH'，跳转到空白页
    // window.location.href = 'about:blank';
  }
}

if (!savedRequestId) {
  // await detectUser();
} 

async function detectUser() {

  try {
    // 获取 FingerprintJS Pro 检测结果
    const fp = await fpPromise;
    const result = await fp.get({ extendedResult: true }); // 确保请求 extendedResult 以获取更多信息

    // 获取设备指纹 ID
    const requestId = result.requestId;
    
    // 将 requestId 存储在 localStorage 中，防止重复获取
    localStorage.setItem('requestId', requestId);

    // 使用 requestId 获取更多访客数据
    await fetchVisitorData(requestId);
  } catch (error) {
    console.error('检测用户信息时出错:', error);
  }
}


async function fetchVisitorData(requestId: string) {
  const apiKey = 'cs3I6B3mwjYyyiOxjPiL'; // 替换为你的 API 密钥
  const region = 'ap'; // 区域，例如 'ap' 为亚太地区
  const url = `https://${region}.api.fpjs.io/events/${requestId}`;

  try {
    const response = await axios.get(url, {
      headers: {
        'Auth-API-Key': apiKey,
        'accept': 'application/json',
      }
    });

    // 从响应中提取相关信息 developerTools
    const data = response.data;
    const botDetection = data.products?.botd?.data;
    const developerTools = data.products?.developerTools?.data;
    const countryCode = data.products.ipInfo.data.v4.geolocation.country?.code; // 获取国家代码

    // 将国家代码保存到 localStorage
    localStorage.setItem('userCountryCode', countryCode);

    // 检测是否为机器人
    if (botDetection?.bot.result !== "notDetected") {
      // 如果检测到是机器人，重定向到 about:blank
      window.location.href = 'about:blank';
    }

    // 判断是否检测到开发者工具被打开
    if (developerTools?.result) {
      // 如果检测到开发者工具被打开，重定向到 about:blank
      window.location.href = 'about:blank';
    }

    if (countryCode !== 'KH') {
      // window.location.href = 'about:blank';
    }

  } catch (error) {
    console.error('Error fetching visitor data:', error);
  }
}

type StateProps = {
  authState: GlobalState['authState'];
  isScreenLocked?: boolean;
  hasPasscode?: boolean;
  isInactiveAuth?: boolean;
  hasWebAuthTokenFailed?: boolean;
  theme: ThemeKey;
};

enum AppScreens {
  auth,
  main,
  lock,
  inactive,
}

const TRANSITION_RENDER_COUNT = Object.keys(AppScreens).length / 2;
const INACTIVE_PAGE_TITLE = `${PAGE_TITLE} ${INACTIVE_MARKER}`;

const App: FC<StateProps> = ({
  authState,
  isScreenLocked,
  hasPasscode,
  isInactiveAuth,
  hasWebAuthTokenFailed,
  theme,
}) => {
  const { disconnect } = getActions();

  const [isInactive, markInactive, unmarkInactive] = useFlag(false);
  const { isMobile } = useAppLayout();
  const isMobileOs = PLATFORM_ENV === 'iOS' || PLATFORM_ENV === 'Android';

  useEffect(() => {
    if (IS_INSTALL_PROMPT_SUPPORTED) {
      setupBeforeInstallPrompt();
    }
  }, []);

  // Prevent drop on elements that do not accept it
  useEffect(() => {
    const body = document.body;
    const handleDrag = (e: DragEvent) => {
      e.preventDefault();
      if (!e.dataTransfer) return;
      if (!(e.target as HTMLElement).dataset.dropzone) {
        e.dataTransfer.dropEffect = 'none';
      } else {
        e.dataTransfer.dropEffect = 'copy';
      }
    };
    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
    };
    body.addEventListener('drop', handleDrop);
    body.addEventListener('dragover', handleDrag);
    body.addEventListener('dragenter', handleDrag);

    return () => {
      body.removeEventListener('drop', handleDrop);
      body.removeEventListener('dragover', handleDrag);
      body.removeEventListener('dragenter', handleDrag);
    };
  }, []);

  // return <Test />;

  let activeKey: number;
  let page: UiLoaderPage | undefined;

  if (isInactive) {
    activeKey = AppScreens.inactive;
  } else if (isScreenLocked) {
    page = 'lock';
    activeKey = AppScreens.lock;
  } else if (authState) {
    switch (authState) {
      case 'authorizationStateWaitPhoneNumber':
        page = 'authPhoneNumber';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateWaitCode':
        page = 'authCode';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateWaitPassword':
        page = 'authPassword';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateWaitRegistration':
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateWaitQrCode':
        page = 'authQrCode';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateClosed':
      case 'authorizationStateClosing':
      case 'authorizationStateLoggingOut':
      case 'authorizationStateReady':
        page = 'main';
        activeKey = AppScreens.main;
        break;
    }
  } else if (hasStoredSession()) {
    page = 'main';
    activeKey = AppScreens.main;
  } else if (hasPasscode) {
    activeKey = AppScreens.lock;
  } else {
    page = isMobileOs ? 'authPhoneNumber' : 'authQrCode';
    activeKey = AppScreens.auth;
  }

  if (activeKey !== AppScreens.lock
    && activeKey !== AppScreens.inactive
    && activeKey !== AppScreens.main
    && parseInitialLocationHash()?.tgWebAuthToken
    && !hasWebAuthTokenFailed) {
    page = 'main';
    activeKey = AppScreens.main;
  }

  useEffect(() => {
    updateSizes();
  }, []);

  useEffect(() => {
    if (IS_MULTITAB_SUPPORTED) return;

    addActiveTabChangeListener(() => {
      disconnect();
      document.title = INACTIVE_PAGE_TITLE;

      markInactive();
    });
  }, [activeKey, disconnect, markInactive]);

  useEffect(() => {
    if (isInactiveAuth) {
      document.title = INACTIVE_PAGE_TITLE;
      markInactive();
    } else {
      document.title = PAGE_TITLE;
      unmarkInactive();
    }
  }, [isInactiveAuth, markInactive, unmarkInactive]);

  const prevActiveKey = usePrevious(activeKey);

  // eslint-disable-next-line consistent-return
  function renderContent() {
    switch (activeKey) {
      case AppScreens.auth:
        return <Auth />;
      case AppScreens.main:
        return <Main isMobile={isMobile} />;
      case AppScreens.lock:
        return <LockScreen isLocked={isScreenLocked} />;
      case AppScreens.inactive:
        return <AppInactive />;
    }
  }

  useLayoutEffect(() => {
    document.body.classList.add(styles.bg);
  }, []);

  useLayoutEffect(() => {
    document.body.style.setProperty(
      '--theme-background-color',
      theme === 'dark' ? DARK_THEME_BG_COLOR : LIGHT_THEME_BG_COLOR,
    );
  }, [theme]);

  return (
    <UiLoader page={page} isMobile={isMobile}>
      <Transition
        name="fade"
        activeKey={activeKey}
        shouldCleanup
        className={buildClassName(
          'full-height',
          (activeKey === AppScreens.auth || prevActiveKey === AppScreens.auth) && 'is-auth',
        )}
        renderCount={TRANSITION_RENDER_COUNT}
      >
        {renderContent}
      </Transition>
    </UiLoader>
  );
};

export default withGlobal(
  (global): StateProps => {
    return {
      authState: global.authState,
      isScreenLocked: global.passcode?.isScreenLocked,
      hasPasscode: global.passcode?.hasPasscode,
      isInactiveAuth: selectTabState(global).isInactive,
      hasWebAuthTokenFailed: global.hasWebAuthTokenFailed || global.hasWebAuthTokenPasswordRequired,
      theme: selectTheme(global),
    };
  },
)(App);
